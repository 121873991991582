import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { withAuth, IAuthHandlerValue } from "./Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToolbox, faCloudUploadAlt, faUser, faEnvelope, faCog } from "@fortawesome/free-solid-svg-icons";

interface NavProps extends IAuthHandlerValue, RouteComponentProps {
}

class Nav extends React.Component<NavProps> {
	render() {
		if (!this.props.profile || !this.props.authHandler) {
			return (
				<div className="nav">
					<div className="logo"><Link to="/">bilderbrett</Link></div>
				</div>
			);
		}

		const modToolboxLink: any = { pathname: "/mod-toolbox" };

		let regex_results: RegExpMatchArray | null;

		if ((regex_results = this.props.location.pathname.match(/\/i\/(.+)(\/|\?|$)/)) !== null) {
			modToolboxLink.state = { image_id: regex_results[1] };
		} else if ((regex_results = this.props.location.pathname.match(/\/user\/(.+)(\/|\?|$)/)) !== null) {
			modToolboxLink.state = { user_name: regex_results[1] };
		}

		return (
			<div className="nav">
				<div className="logo"><Link to="/"><img src="/assets/icon_64.png" /><span>bilderbrett</span></Link></div>
				<div className="right">
					{this.props.profile.mod
						? (
							<Link to={modToolboxLink} className="circle-button">
								<FontAwesomeIcon icon={faToolbox} className="action mod" />
							</Link>
						)
						: null
					}
					<Link to="/upload" className="circle-button">
						<FontAwesomeIcon icon={faCloudUploadAlt} />
					</Link>
					<Link to={"/user/" + this.props.profile.name} className="circle-button">
						<FontAwesomeIcon icon={faUser} />
					</Link>
					<Link to="/messages" className="circle-button">
						<FontAwesomeIcon icon={faEnvelope} />
						{this.props.profile.UnreadMessages > 0 ? <div className="badge"></div> : null}
					</Link>
					<Link to="/settings" className="circle-button">
						<FontAwesomeIcon icon={faCog} />
					</Link>
				</div>
			</div>
		);
	}
}

export default withAuth(withRouter(Nav));