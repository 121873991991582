import React from "react";
import Axios, { AxiosResponse } from "axios";

interface ProfileData {
	name: string
	karma: number
	category: string
	color: string
	mod?: boolean
	UnreadMessages: number
}
interface IAuthHandlerValue {
	profile?: ProfileData
	authHandler?: AuthHandler
}

interface IAuthHandlerState extends IAuthHandlerValue {
	loading: boolean
	refresh_timer?: number
}

class AuthHandler extends React.Component<any, IAuthHandlerState> {
	constructor(props: any) {
		super(props);

		this.state = { authHandler: this, loading: false };

		const p = localStorage.getItem("profile");
		if (p !== null) {
			this.state = { authHandler: this, profile: JSON.parse(p), loading: false };
		}
	}

	componentDidMount() {
		if (this.state.profile) {
			this.scheduleRefresh();
		}
		this.refresh();
	}

	componentDidUpdate(prevProps: any, prevState: IAuthHandlerState) {
		if (!prevState.profile && this.state.profile) {
			this.scheduleRefresh();
		} else if (prevState.profile && !this.state.profile) {
			this.disableRefresh();
		}
	}

	scheduleRefresh = () => {
		if (!this.state.refresh_timer) {
			this.setState({ refresh_timer: window.setInterval(this.refresh, 30*1000) });
		}
	}

	disableRefresh = () => {
		if (this.state.refresh_timer) {
			window.clearInterval(this.state.refresh_timer);
			this.setState({ refresh_timer: undefined });
		}
	}

	refresh = () => {
		this.setState({ loading: true });
		Axios
			.get("/api/user")
			.then(res => {
				this.setState({ loading: false, profile: res.data });
				localStorage.setItem("profile", JSON.stringify(res.data));
			})
			.catch(err => {
				if (err.response && err.response.data && err.response.data.error == "Du bist nicht angemeldet") {
					localStorage.removeItem("profile");
					this.setState({ loading: false, profile: undefined });
					return;
				}
				this.setState({ loading: false });
			});
	}

	login = (username: string, password: string) => {
		return new Promise<AxiosResponse<ProfileData>>((resolve, reject) => {
			Axios
				.post("/api/user/login", { username, password })
				.then(res => {
					this.setState({ profile: res.data });
					localStorage.setItem("profile", JSON.stringify(res.data));
					resolve(res);
				})
				.catch(err => {
					this.setState({ profile: undefined });
					reject(err);
				});
		});
	}

	register = (username: string, password: string, code: string) => {
		return new Promise<AxiosResponse<ProfileData>>((resolve, reject) => {
			Axios
				.post("/api/user/register", { username, password, code })
				.then(res => {
					this.setState({ profile: res.data });
					localStorage.setItem("profile", JSON.stringify(res.data));
					resolve(res);
				})
				.catch(err => {
					this.setState({ profile: undefined });
					reject(err);
				});
		});
	}

	logout = () => {
		return new Promise<ProfileData>((resolve, reject) => {
			Axios
				.post("/api/user/logout", {})
				.then(_ => {
					this.setState({ profile: undefined });
					localStorage.removeItem("profile");
					resolve();
				})
				.catch(err => {
					if (err.response && err.response.status == 400 && err.response.data) {
						reject(err.response.data);
					} else {
						reject({ error: "Irgendwas am Server ist gerade doof" });
					}
				});
		});
	}

	render() {
		const { authHandler, profile } = this.state;
		const value = { authHandler, profile };
		return (
			<AuthContext.Provider value={value}>
				{this.props.children}
			</AuthContext.Provider>
		)
	}
}

const AuthContext = React.createContext<IAuthHandlerValue>({});

const withAuth = (Component: any) =>
	class withAuth extends React.Component {
		static displayName = "withAuth(" + (Component.displayName || Component.name) + ")";
		render() {
			return (
				<AuthContext.Consumer>
					{value =>
						<Component {...this.props} {...value} />
					}
				</AuthContext.Consumer>
			);
		}
	}

export {
	ProfileData,
	IAuthHandlerValue,
	AuthHandler,
	AuthContext,
	withAuth,
}