import React, { FormEvent, ChangeEvent } from "react";
import Axios from "axios";
import { IAuthHandlerValue, AuthHandler, withAuth } from "components/Auth";
import Toggle from "components/Toggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faAngleRight, faExclamationTriangle, faCheck, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

interface SettingsData {
	invites: string[]
	ShowProfileUploads: boolean
	ShowProfileFavorites: boolean
	ShowProfileComments: boolean
	PushoverUserKey: string
	NotifyTagged: boolean
	NotifyResponse: boolean
}

interface SettingsProps extends IAuthHandlerValue {
}

interface SettingsState {
	loading: boolean
	error?: string
	info?: SettingsData
	loading_privacy: boolean
	error_privacy?: string
	success_privacy?: boolean
	loading_notifications: boolean
	error_notifications?: string
	success_notifications?: boolean
}

class Settings extends React.Component<SettingsProps, SettingsState> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: true,
			loading_privacy: false,
			loading_notifications: false,
		};
	}

	fetchInfo = () => {
		Axios
			.get<SettingsData>("/api/user/settings")
			.then(res => {
				this.setState({
					loading: false,
					info: res.data,
					error: undefined,
				});
			})
			.catch(err => {
				this.setState({
					loading: false,
					error: err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Irgendwas am Server ist gerade doof",
				});
			});
	}

	componentDidMount() {
		document.title = "Einstellungen | bilderbrett";
		this.fetchInfo();
	}

	onPrivacySubmit = (event: FormEvent) => {
		event.preventDefault();
		if (this.state.loading_privacy || !this.state.info) {
			return;
		}
		this.setState({
			loading_privacy: true,
			error_privacy: undefined,
			success_privacy: undefined,
		});
		const {
			ShowProfileUploads,
			ShowProfileFavorites,
			ShowProfileComments,
		} = this.state.info;
		Axios
			.post("/api/user/settings/privacy", {
				ShowProfileUploads,
				ShowProfileFavorites,
				ShowProfileComments,
			})
			.then(res => {
				this.setState({
					loading_privacy: false,
					error_privacy: undefined,
					success_privacy: true,
				});
			})
			.catch(err => {
				this.setState({
					loading_privacy: false,
					error_privacy: err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Irgendwas am Server ist gerade doof",
				});
			});
	}

	onPrivacyToggleChange = (name: string, on: boolean) => {
		const { info } = this.state;
		if (!info) return;
		switch (name) {
			case "ShowProfileUploads":
				info.ShowProfileUploads = on;
				break;
			case "ShowProfileFavorites":
				info.ShowProfileFavorites = on;
				break;
			case "ShowProfileComments":
				info.ShowProfileComments = on;
				break;
		}
		this.setState({ info, error_privacy: undefined, success_privacy: undefined });
	}

	onNotificationsChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { info } = this.state;
		if (!info) return;
		const { target } = event;
		switch (target.name) {
			case "PushoverUserKey":
				info.PushoverUserKey = target.value;
				break;
		}
		this.setState({ info, error_notifications: undefined, success_notifications: undefined });
	}

	onNotificationsToggleChange = (name: string, on: boolean) => {
		const { info } = this.state;
		if (!info) return;
		switch (name) {
			case "NotifyTagged":
				info.NotifyTagged = on;
				break;
			case "NotifyResponse":
				info.NotifyResponse = on;
				break;
		}
		this.setState({ info, error_notifications: undefined, success_notifications: undefined });
	}

	onNotificationsSubmit = (event: FormEvent) => {
		event.preventDefault();
		if (this.state.loading_notifications || !this.state.info) {
			return;
		}
		this.setState({
			loading_notifications: true,
			error_notifications: undefined,
			success_notifications: undefined,
		});
		const {
			PushoverUserKey,
			NotifyTagged,
			NotifyResponse,
		} = this.state.info;
		Axios
			.post("/api/user/settings/notifications", {
				PushoverUserKey,
				NotifyTagged,
				NotifyResponse,
			})
			.then(res => {
				this.setState({
					loading_notifications: false,
					error_notifications: undefined,
					success_notifications: true,
				});
			})
			.catch(err => {
				this.setState({
					loading_notifications: false,
					error_notifications: err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Irgendwas am Server ist gerade doof",
				});
			});
	}

	onNSFWToggle = (name: string, on: boolean) => {
		localStorage.setItem("nsfw", on ? "1" : "0")
		this.forceUpdate()
	}

	render() {
		if (this.state.loading && !this.state.info) {
			return (
				<div className="card">
					<div className="header">
						<div className="title">Einstellungen</div>
						<div className="options">
							<FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
						</div>
					</div>
				</div>
			);
		}

		if (!this.state.info) {
			return (
				<div className="card">
					<div className="header">
						<div className="title">Einstellungen</div>
						<div className="options">
							<FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
						</div>
					</div>
					{this.state.error
						? <div className="content"><div className="error">{this.state.error}</div></div>
						: null
					}
				</div>
			);
		}

		const info = this.state.info as SettingsData;
		const authHandler = this.props.authHandler as AuthHandler;

		return (
			<>
				<div className="card">
					<div className="header">
						<div className="title">Einstellungen</div>
						<div className="options">
						</div>
					</div>
					<div className="content">
						<button className="passive" onClick={authHandler.logout}>
							Abmelden <FontAwesomeIcon icon={faSignOutAlt} fixedWidth />
						</button>
					</div>
				</div>

				{/*
				<div className="card">
					<div className="header">
						<div className="title">Filter</div>
					</div>
					<div className="content">
						<div className="form-label left-right no-margin">
							<div>NSFW:</div>
							<Toggle
								name="NSFW"
								on={localStorage.getItem("nsfw") == "1"}
								onChange={this.onNSFWToggle} />
						</div>
					</div>
				</div>
				*/}

				<div className="card">
					<div className="header">
						<div className="title">Profil Privatsphäre</div>
						<div className="options">
							{this.state.loading_privacy
								? <FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
								: null
							}
							{this.state.error_privacy
								? <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
								: null
							}
							{this.state.success_privacy
								? <FontAwesomeIcon icon={faCheck} fixedWidth />
								: null
							}
						</div>
					</div>
					<div className="content">
						<form onSubmit={this.onPrivacySubmit}>
							<div className="form-label left-right">
								<div>Hochgeladene Bilder:</div>
								<Toggle
									name="ShowProfileUploads"
									on={info.ShowProfileUploads}
									onChange={this.onPrivacyToggleChange}
									disabled={this.state.loading_privacy} />
							</div>
							<div className="form-label left-right">
								<div>Favoriten:</div>
								<Toggle
									name="ShowProfileFavorites"
									on={info.ShowProfileFavorites}
									onChange={this.onPrivacyToggleChange}
									disabled={this.state.loading_privacy} />
							</div>
							<div className="form-label left-right">
								<div>Kommentare:</div>
								<Toggle
									name="ShowProfileComments"
									on={info.ShowProfileComments}
									onChange={this.onPrivacyToggleChange}
									disabled={this.state.loading_privacy} />
							</div>
							<button type="submit" disabled={this.state.loading_privacy}>
								Speichern <FontAwesomeIcon icon={this.state.loading_privacy ? faSyncAlt : faAngleRight} spin={this.state.loading_privacy} fixedWidth />
							</button>
							{this.state.error_privacy
								? <><br /><br /><div className="error">{this.state.error_privacy}</div></>
								: null
							}
							{this.state.success_privacy
								? <><br /><br /><div className="success">Änderungen gespeichert</div></>
								: null
							}
						</form>
					</div>
				</div>

				<div className="card">
					<div className="header">
						<div className="title">Benachrichtigungen</div>
						<div className="options">
							{this.state.loading_notifications
								? <FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
								: null
							}
							{this.state.error_notifications
								? <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
								: null
							}
							{this.state.success_notifications
								? <FontAwesomeIcon icon={faCheck} fixedWidth />
								: null
							}
						</div>
					</div>
					<div className="content">
						<form onSubmit={this.onNotificationsSubmit}>
							<div className="form-label"><a href="https://pushover.net/" target="_blank">Pushover</a> User Key:</div>
							<input className="form-label max-width" name="PushoverUserKey" type="text" onChange={this.onNotificationsChange} value={this.state.info.PushoverUserKey} disabled={this.state.loading_notifications} />
							<div className="form-label left-right">
								<div>Markierungen:</div>
								<Toggle
									name="NotifyTagged"
									on={info.NotifyTagged}
									onChange={this.onNotificationsToggleChange}
									disabled={this.state.loading_notifications} />
							</div>
							<div className="form-label left-right">
								<div>Antworten:</div>
								<Toggle
									name="NotifyResponse"
									on={info.NotifyResponse}
									onChange={this.onNotificationsToggleChange}
									disabled={this.state.loading_notifications} />
							</div>
							<button type="submit" disabled={this.state.loading_notifications}>
								Speichern <FontAwesomeIcon icon={this.state.loading_notifications ? faSyncAlt : faAngleRight} spin={this.state.loading_notifications} fixedWidth />
							</button>
							{this.state.error_notifications
								? <><br /><br /><div className="error">{this.state.error_notifications}</div></>
								: null
							}
							{this.state.success_notifications
								? <><br /><br /><div className="success">Änderungen gespeichert</div></>
								: null
							}
						</form>
					</div>
				</div>

				<div className="card">
					<div className="header">
						<div className="title">Einladungen</div>
						<div className="options">
						</div>
					</div>
					<div className="content">
						{info.invites.length > 0
							? (
								<ul style={{ fontFamily: "monospace" }}>
									{info.invites.map(code => <li key={code}>{code}</li>)}
								</ul>
							)
							: <span>sieht so aus, als wären gerade keine da für dich ¯\_(ツ)_/¯</span>
						}
					</div>
				</div>
			</>
		);
	}
}

export default withAuth(Settings);