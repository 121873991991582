import React, { FormEvent, ChangeEvent } from "react"
import { Redirect, RouteComponentProps } from "react-router"

import { withAuth, IAuthHandlerValue } from "components/Auth"
import Axios from "axios"
import Video from "components/Video"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSyncAlt, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import TagsSelect from "components/TagsSelect"
import Toggle from "components/Toggle"

interface UploadState {
	loading: boolean
	file?: File
	file_data_url?: string
	remote_url?: string
	error?: string
	tags: string[]
	nsfw: boolean
}

interface UploadProps extends IAuthHandlerValue, RouteComponentProps {
}

class Upload extends React.Component<UploadProps, UploadState> {
	constructor(props: UploadProps) {
		super(props)
		this.state = {
			loading: false,
			tags: [],
			nsfw: false,
		}
	}

	arrayBufferToBase64 = (buffer: ArrayBuffer) => {
		let binary = ''
		const bytes = new Uint8Array(buffer), len = bytes.byteLength
		for (var i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i])
		}
		return window.btoa(binary)
	}

	onSubmit = (event: FormEvent) => {
		event.preventDefault()
		if (!this.state.file || !this.state.file_data_url) {
			return
		}
		this.setState({ loading: true })

		const { file } = this.state

		const reader = new FileReader()
		reader.readAsArrayBuffer(file.slice())
		reader.onload = (event: ProgressEvent) => {
			Axios
				.post("/api/images", {
					original_name: file.name,
					size: file.size,
					data: this.arrayBufferToBase64(reader.result as ArrayBuffer),
					tags: this.state.tags,
					NSFW: this.state.nsfw,
				})
				.then(res => this.props.history.push("/i/" + res.data.image_id))
				.catch(err => {
					let errorMessage = "Irgendwas am Server ist gerade doof"
					if (err.response) {
						if (err.response.status == 413) {
							errorMessage = "Datei ist zu groß. Warum auch immer, aber scheinbar darf man nur 20MB"
						} else if (err.response.data && err.response.data.error) {
							errorMessage = err.response.data.error
						}
					}
					this.setState({ loading: false, error: errorMessage })
				})
		}
	}

	onChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (!event.currentTarget.files || event.currentTarget.files.length == 0) {
			this.setState({ file: undefined, file_data_url: undefined, error: undefined })
			return
		}
		const file = event.currentTarget.files[0]
		if (!file.type.match(/^(image|video)\//)) {
			this.setState({ file: undefined, file_data_url: undefined, error: "Also es sollte schon ein Bild oder ein Video sein" })
			return
		}
		this.setState({ file: file, error: undefined })

		const reader = new FileReader()
		reader.readAsDataURL(file.slice())
		reader.onload = (event: ProgressEvent) => {
			if (this.state.file === file && typeof reader.result === "string") {
				const result = reader.result.replace(/^data:application\/octet-stream;/, "data:" + file.type + ";")
				this.setState({ file_data_url: result })
			}
		}
	}

	onChangeURL = (event: ChangeEvent<HTMLInputElement>) => {
		this.setState({ remote_url: event.currentTarget.value ? event.currentTarget.value : undefined })
	}

	componentDidMount() {
		document.title = "Hochladen | bilderbrett"
	}

	onChangeTags = (values: string[]) => {
		this.setState({ tags: values })
	}

	onChangeNSFW = (name: string, on: boolean) => {
		this.setState({ nsfw: on })
	}

	render() {
		if (!this.props.profile) {
			return <Redirect to={{ pathname: "/" }} />
		}

		return (
			<div className="card">
				<div className="header">
					<div className="title">Hochladen</div>
					<div className="options">
						{this.state.loading
							? <FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
							: null
						}
					</div>
				</div>
				<div className="content">
					<form onSubmit={this.onSubmit}>
						<div className="form-label">Marken:</div>
						<TagsSelect
							values={this.state.tags}
							onChange={this.onChangeTags}
							disabled={this.state.loading}
						/>
						<br />
						<div className="form-label">Datei:</div>
						<input type="file" onChange={this.onChange}></input>
						{/*
						<br />
						<div className="form-label">oder URL:</div>
						<input type="text" onChange={this.onChangeURL} value={this.state.remote_url}></input>
						*/}
						{/*
						<br />
						<div className="form-label left-right">
							<div>NSFW:</div>
							<Toggle
								name="NSFW"
								on={this.state.nsfw}
								onChange={this.onChangeNSFW}
								disabled={this.state.loading} />
						</div>
						*/}
						<br /><br />
						<button type="submit" disabled={this.state.loading}>
							Hochladen <FontAwesomeIcon icon={this.state.loading ? faSyncAlt : faAngleRight} spin={this.state.loading} fixedWidth />
						</button>
						{this.state.file && this.state.file_data_url
							? <>
								<br /><br /><div style={{ textAlign: "center" }}>
									{this.state.file.type.startsWith("image/")
										? <img
											src={this.state.file_data_url}
											datatype={this.state.file.type}
											style={{ maxWidth: "256px", maxHeight: "256px", }} />
										: null
									}
									{this.state.file.type.startsWith("video/")
										? <Video
											src={this.state.file_data_url}
											datatype={this.state.file.type}
											maxWidth={256}
											maxHeight={256} />
										: null
									}
								</div>
							</>
							: this.state.remote_url
								? <>
									<br /><br /><div style={{ textAlign: "center" }}>
										<img
											src={this.state.remote_url}
											style={{ maxWidth: "256px", maxHeight: "256px", }} />
									</div>
								</>
								: null
						}
						{this.state.error !== undefined
							? <><br /><br /><div className="error">{this.state.error}</div></>
							: null
						}
					</form>
				</div>
			</div>
		)
	}
}

export default withAuth(Upload)