import React from "react";

class NotFound extends React.Component {
	componentDidMount() {
		document.title = "404 | bilderbrett";
	}

	render() {
		return (
			<div className="card">
				<div className="header">
					<div className="title">404 - NICHT GEFUNDEN :(</div>
					<div className="options"></div>
				</div>
				<div className="content">
					Es scheint als hättest du dich verlaufen, denn unter der Adresse wo du hin wolltest gibt's leider nichts. ¯\_(ツ)_/¯
				</div>
			</div>
		);
	}
}

export default NotFound;