import "./style/App.scss";
import "react-select/dist/react-select.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import ScrollToTop from "components/ScrollToTop";
import Nav from "components/Nav";
import PrivateRoute from "components/PrivateRoute";
import { AuthHandler } from "components/Auth";

import Gallery from "views/Gallery";
import Login from "views/Login";
import Item from "views/Item";
import NotFound from "views/NotFound";
import Upload from "views/Upload";
import User from "views/User";
import Settings from "views/Settings";
import Register from "views/Register";
import ModToolbox from "views/ModToolbox";

import Axios from "axios";
import Messages from "views/Messages";
Axios.defaults.withCredentials = true;

class App extends React.Component {
	render() {
		return (
			<AuthHandler>
				<BrowserRouter>
					<ScrollToTop />
					<Nav />
					<div className="content">
						<Switch>
							<PrivateRoute exact path="/" component={Gallery} />
							<PrivateRoute exact path="/user/:username/:gallery" component={Gallery} />
							<PrivateRoute exact path="/i/:id" component={Item} />
							<PrivateRoute exact path="/user/:username/:gallery/i/:id" component={Item} />
							<PrivateRoute exact path="/upload" component={Upload} />
							<PrivateRoute exact path="/user/:name" component={User} />
							<PrivateRoute exact path="/settings" component={Settings} />
							<PrivateRoute exact path="/mod-toolbox" component={ModToolbox} require_mod />
							<PrivateRoute exact path="/messages" component={Messages} />
							<Route exact path="/login" component={Login} />
							<Route exact path="/register" component={Register} />
							<Route component={NotFound} />
						</Switch>
					</div>
				</BrowserRouter>
			</AuthHandler>
		);
	}
}

ReactDOM.render(<App />, document.getElementById("app"));