import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Axios from "axios";

import { withAuth, IAuthHandlerValue } from "components/Auth";
import richtext from "utils/richtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

interface ImageInfo {
	id: string
	timestamp: number
	uploader: string
	ext: string
	karma: number
	voted_karma: number
	image_url: string
	thumbnail_url: string
	is_deleted?: boolean
}

interface GalleryState {
	has_newer: boolean
	has_older: boolean
	loading: boolean
	images: ImageInfo[]
	error?: string
}

interface GalleryMatchParams {
	username?: string
	gallery?: string
}

interface GalleryProps extends IAuthHandlerValue, RouteComponentProps<GalleryMatchParams> {
}

class Gallery extends React.Component<GalleryProps, GalleryState> {
	constructor(props: GalleryProps) {
		super(props);
		this.state = {
			has_newer: false,
			has_older: true,
			loading: true,
			images: [],
		};
	}

	newestImageID() {
		if (this.state.images.length > 0) {
			return this.state.images[0].id;
		}
	}

	oldestImageID() {
		if (this.state.images.length > 0) {
			return this.state.images[this.state.images.length - 1].id;
		}
	}

	setTitle() {
		const { params } = this.props.match;
		if (params.username) {
			switch (params.gallery) {
				case "uploads":
					document.title = "Bilder von " + params.username + " | bilderbrett";
					return;
				case "favorites":
					document.title = "Favoriten von " + params.username + " | bilderbrett";
					return;
			}
		}
		document.title = "bilderbrett";
	}

	componentDidMount() {
		this.setTitle()
		this.fetchOlder();
		document.addEventListener("scroll", this.onScroll);
	}

	componentWillUnmount() {
		document.removeEventListener("scroll", this.onScroll);
	}

	componentDidUpdate(prevProps: GalleryProps, prevState: GalleryState) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.setTitle();
			this.setState({
				has_newer: false,
				has_older: true,
				loading: true,
				error: undefined,
				images: [],
			}, () => this.fetchOlder());
			return;
		}

		if (this.state.images.length !== prevState.images.length) {
			this.checkScroll();
			return;
		}

		if (this.props.location.key !== prevProps.location.key) {
			this.fetchNewer();
			return;
		}
	}

	onScroll = (event: Event) => {
		this.checkScroll();
	}

	checkScroll = () => {
		if (!document.scrollingElement || this.state.loading) {
			return;
		}
		const scrollBuffer = document.scrollingElement.scrollHeight - document.scrollingElement.clientHeight - document.scrollingElement.scrollTop;
		if (this.state.has_older && scrollBuffer < 128 * 4) {
			this.fetchOlder();
		}
	}

	fetchNewer() {
		this.setState({
			loading: true,
		});
		Axios
			.get(this.buildURL("/api/images", {
				id: this.newestImageID(),
				search: this.buildSearch(),
				reverse: 1,
				nsfw: localStorage.getItem("nsfw") == "1" ? "1" : "0",
			}))
			.then(res => {
				this.setState({
					images: res.data.images.concat(this.state.images),
					has_newer: res.data.has_more,
					loading: false,
				});
			})
			.catch(err => {
				this.setState({
					loading: false,
					has_newer: false,
					error: err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Irgendwas am Server ist gerade doof",
				})
			});
	}

	fetchOlder() {
		this.setState({
			loading: true,
		});
		Axios
			.get(this.buildURL("/api/images", {
				id: this.oldestImageID(),
				search: this.buildSearch(),
				nsfw: localStorage.getItem("nsfw") == "1" ? "1" : "0",
			}))
			.then(res => {
				this.setState({
					images: this.state.images.concat(res.data.images),
					has_older: res.data.has_more,
					loading: false,
				});
			})
			.catch(err => {
				this.setState({
					loading: false,
					has_older: false,
					error: err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Irgendwas am Server ist gerade doof",
				})
			});
	}

	buildSearch = () => {
		const { params } = this.props.match;
		if (params.username && params.gallery) {
			return params.username + ":" + params.gallery;
		}
	}

	buildURL = (path: string, obj: { [key: string]: any }) => {
		let result: string[] = [];
		Object.keys(obj).forEach(k => {
			if (obj[k] == undefined) {
				return;
			}
			result.push(encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]));
		});
		return path + (result.length > 0 ? "?" + result.join("&") : "");
	}

	render() {
		let search = "";

		const { params } = this.props.match;
		if (params.username) {
			switch (params.gallery) {
				case "uploads":
					search = "Bilder von @" + params.username;
					break;
				case "favorites":
					search = "Favoriten von @" + params.username;
					break;
			}
		} else {
			search = "Neue Bilder";
		}

		const galleryName = this.props.match.params.gallery ? this.props.match.params.gallery : ""

		return (
			<>
				<div className="search-bar">
					<div>
						<span>{richtext.formatText(search)}</span>
						{this.state.loading ? <FontAwesomeIcon icon={faSyncAlt} spin fixedWidth /> : null}
						{this.state.error ? <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth /> : null}
					</div>
				</div>
				<div className="gallery">
					{this.state.images.map(img => (
						<Link key={img.id} to={galleryName + "/i/" + img.id} className={img.is_deleted ? "is_deleted" : ""}>
							<img src={"/images/" + img.thumbnail_url} />
						</Link>
					))}
				</div>
			</>
		);
	}
}

export default withAuth(Gallery);