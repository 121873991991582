import React, { FormEvent, ChangeEvent } from "react";
import Axios from "axios";
import { RouteComponentProps } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Radio from "components/Radio";

interface ModToolboxState {
	loading: boolean
	image_id: string
	image_action: string
	image_result?: boolean
	image_error?: string
	user_name: string
	user_action: string
	user_result?: boolean
	user_error?: string
}

interface ModToolboxProps extends RouteComponentProps {
}

class ModToolbox extends React.Component<ModToolboxProps, ModToolboxState> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			image_id: this.props.location.state && this.props.location.state.image_id ? this.props.location.state.image_id : "",
			image_action: "delete",
			user_name: this.props.location.state && this.props.location.state.user_name ? this.props.location.state.user_name : "",
			user_action: "ban_24h",
		};
	}

	componentDidMount() {
		document.title = "Stockknecht-Werkzeuge | bilderbrett";
	}

	onImageSubmit = (event: FormEvent) => {
		event.preventDefault();
		if (this.state.loading) return;
		this.setState({ loading: true, image_result: undefined, image_error: undefined });
		Axios
			.post("/api/mod/image", { ID: this.state.image_id, Action: this.state.image_action })
			.then(res => {
				this.setState({ loading: false, image_result: true });
			})
			.catch(err => {
				if (err.response && err.response.data && err.response.data.error) {
					this.setState({ loading: false, image_error: err.response.data.error });
				} else {
					this.setState({ loading: false, image_error: "Irgendwas am Server ist gerade doof" });
				}
			});
	}

	onUserSubmit = (event: FormEvent) => {
		event.preventDefault();
		if (this.state.loading) return;
		this.setState({ loading: true, user_result: undefined, user_error: undefined });
		Axios
			.post("/api/mod/user", { Name: this.state.user_name, Action: this.state.user_action })
			.then(res => {
				this.setState({ loading: false, user_result: true });
			})
			.catch(err => {
				if (err.response && err.response.data && err.response.data.error) {
					this.setState({ loading: false, user_error: err.response.data.error });
				} else {
					this.setState({ loading: false, user_error: "Irgendwas am Server ist gerade doof" });
				}
			});
	}

	onChange = (event: ChangeEvent<HTMLInputElement>) => {
		switch (event.currentTarget.name) {
			case "image_id":
				this.setState({ image_id: event.currentTarget.value })
				break;
			case "user_name":
				this.setState({ user_name: event.currentTarget.value })
				break;
		}
	}

	onRadioChange = (name: string, on: boolean) => {
		if (!on) return
		var namepp = name.split("/"), name = namepp[0], value = namepp[1]
		switch (name) {
			case "image_action":
				this.setState({ image_action: value })
				break
			case "user_action":
				this.setState({ user_action: value })
				break
		}
	}

	render() {
		return (
			<>
				<div className="card">
					<div className="header">
						<div className="title">Stockknecht-Werkzeuge</div>
						<div className="options">
							{this.state.loading
								? <FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
								: null
							}
						</div>
					</div>
				</div>

				<div className="card">
					<div className="header">
						<div className="title">Bild</div>
						<div className="options">
							{this.state.loading
								? <FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
								: null
							}
						</div>
					</div>
					<div className="content">
						<form onSubmit={this.onImageSubmit}>
							<div className="form-label">ID:</div>
							<input className="max-width" name="image_id" type="text" onChange={this.onChange} value={this.state.image_id} disabled={this.state.loading} />
							<br /><br />
							<div className="form-label left-right">
								<div>Löschen:</div>
								<Radio
									name="image_action/delete"
									on={this.state.image_action == "delete"}
									onChange={this.onRadioChange}
									disabled={this.state.loading} />
							</div>
							<div className="form-label left-right">
								<div>Wiederherstellen:</div>
								<Radio
									name="image_action/restore"
									on={this.state.image_action == "restore"}
									onChange={this.onRadioChange}
									disabled={this.state.loading} />
							</div>
							<button type="submit" disabled={this.state.loading}>
								Anwenden <FontAwesomeIcon icon={this.state.loading ? faSyncAlt : faAngleRight} spin={this.state.loading} fixedWidth />
							</button>
							{this.state.image_result
								? <><br /><br /><div className="success">Aktion erfolgreich angewandt</div></>
								: null
							}
							{this.state.image_error !== undefined
								? <><br /><br /><div className="error">{this.state.image_error}</div></>
								: null
							}
						</form>
					</div>
				</div>

				<div className="card">
					<div className="header">
						<div className="title">Nutzer</div>
						<div className="options">
							{this.state.loading
								? <FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
								: null
							}
						</div>
					</div>
					<div className="content">
						<form onSubmit={this.onUserSubmit}>
							<div className="form-label">Name:</div>
							<input className="max-width" name="user_name" type="text" onChange={this.onChange} value={this.state.user_name} disabled={this.state.loading} />
							<br /><br />
							<div className="form-label left-right">
								<div>24 Stunden sperren:</div>
								<Radio
									name="user_action/ban_24h"
									on={this.state.user_action == "ban_24h"}
									onChange={this.onRadioChange}
									disabled={this.state.loading} />
							</div>
							<div className="form-label left-right">
								<div>3 Tage sperren:</div>
								<Radio
									name="user_action/ban_3d"
									on={this.state.user_action == "ban_3d"}
									onChange={this.onRadioChange}
									disabled={this.state.loading} />
							</div>
							<div className="form-label left-right">
								<div>7 Tage sperren:</div>
								<Radio
									name="user_action/ban_7d"
									on={this.state.user_action == "ban_7d"}
									onChange={this.onRadioChange}
									disabled={this.state.loading} />
							</div>
							<div className="form-label left-right">
								<div>Dauerhaft sperren:</div>
								<Radio
									name="user_action/ban_permanent"
									on={this.state.user_action == "ban_permanent"}
									onChange={this.onRadioChange}
									disabled={this.state.loading} />
							</div>
							<div className="form-label left-right">
								<div>Sperre aufheben:</div>
								<Radio
									name="user_action/unban"
									on={this.state.user_action == "unban"}
									onChange={this.onRadioChange}
									disabled={this.state.loading} />
							</div>
							<div className="form-label left-right">
								<div>Einladung geben:</div>
								<Radio
									name="user_action/add_invite"
									on={this.state.user_action == "add_invite"}
									onChange={this.onRadioChange}
									disabled={this.state.loading} />
							</div>
							<button type="submit" disabled={this.state.loading}>
								Anwenden <FontAwesomeIcon icon={this.state.loading ? faSyncAlt : faAngleRight} spin={this.state.loading} fixedWidth />
							</button>
							{this.state.user_result
								? <><br /><br /><div className="success">Aktion erfolgreich angewandt</div></>
								: null
							}
							{this.state.user_error !== undefined
								? <><br /><br /><div className="error">{this.state.user_error}</div></>
								: null
							}
						</form>
					</div>
				</div>
			</>
		);
	}
}

export default ModToolbox;