import React, { FormEvent, ChangeEvent } from "react";
import { Redirect } from "react-router";

import { withAuth, IAuthHandlerValue } from "components/Auth";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faAngleRight } from "@fortawesome/free-solid-svg-icons";

interface RegisterState {
	loading: boolean
	username: string
	password: string
	code: string
	error?: string
}

interface RegisterProps extends IAuthHandlerValue {
}

class Register extends React.Component<RegisterProps, RegisterState> {
	constructor(props: RegisterProps) {
		super(props);
		this.state = {
			loading: false,
			username: "",
			password: "",
			code: "",
		}
	}

	onSubmit = (event: FormEvent) => {
		event.preventDefault();
		if (!this.props.authHandler) return;
		this.setState({ loading: true, error: undefined });
		this.props.authHandler
			.register(this.state.username, this.state.password, this.state.code)
			.catch(err => {
				this.setState({
					loading: false,
					error: err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Irgendwas am Server ist gerade doof",
				});
			});
	}

	onChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.currentTarget.name === "username") {
			this.setState({ username: event.currentTarget.value });
		}
		if (event.currentTarget.name === "password") {
			this.setState({ password: event.currentTarget.value });
		}
		if (event.currentTarget.name === "code") {
			this.setState({ code: event.currentTarget.value });
		}
	}

	componentDidMount() {
		document.title = "Neu hier? | bilderbrett";
	}

	render() {
		if (this.props.profile) {
			return <Redirect to={{ pathname: "/" }} />;
		}

		return (
			<div className="card">
				<div className="header">
					<div className="title">Neu hier?</div>
					<div className="options">
						{this.state.loading
							? <FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
							: null
						}
					</div>
				</div>
				<div className="content">
					<form onSubmit={this.onSubmit}>
						<div className="form-label">Name:</div>
						<input className="max-width" name="username" type="text" autoFocus onChange={this.onChange} value={this.state.username} disabled={this.state.loading} />
						<br />
						<br />
						<div className="form-label">Passwort:</div>
						<input className="max-width" name="password" type="password" onChange={this.onChange} value={this.state.password} disabled={this.state.loading} />
						<br />
						<br />
						<div className="form-label">Einladung:</div>
						<input className="max-width" name="code" type="text" onChange={this.onChange} value={this.state.code} disabled={this.state.loading} />
						<br />
						<br />
						<div className="buttons">
							<button type="submit" disabled={this.state.loading}>
								Weiter <FontAwesomeIcon icon={this.state.loading ? faSyncAlt : faAngleRight} spin={this.state.loading} fixedWidth />
							</button>
							<Link to="/login" className="button passive">
								Anmelden
							</Link>
						</div>
						{this.state.error !== undefined
							? <><br /><br /><div className="error">{this.state.error}</div></>
							: null
						}
					</form>
				</div>
			</div>
		);
	}
}

export default withAuth(Register);