import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"

interface IToggleProps {
	name: string
	on: boolean
	onChange: (name: string, on: boolean) => void
	disabled?: boolean
}

class Toggle extends React.Component<IToggleProps> {
	constructor(props: IToggleProps) {
		super(props)
	}

	onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (this.props.disabled) {
			return
		}
		this.props.onChange(this.props.name, !this.props.on)
	}

	render() {
		return (
			<div className={"toggle"+(this.props.on?" on":" off")+(this.props.disabled?" disabled":"")} onClick={this.onClick}>
				<div className="toggler">
					<FontAwesomeIcon icon={this.props.on?faCheck:faTimes} />
				</div>
			</div>
		)
	}
}

export default Toggle