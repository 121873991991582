import React, { CSSProperties } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay, faPause, faVolumeUp, faVolumeMute, faVolumeOff, faVolumeDown } from "@fortawesome/free-solid-svg-icons"
import ReactDOM from "react-dom";

interface VideoProps {
	src: string
	datatype?: string
	maxWidth?: number
	maxHeight?: number
}

interface VideoState {
	playing: boolean
	muted: boolean
	volume: number
	progress: number
	containerElement?: HTMLDivElement
	videoTimer?: number
	videoElement?: HTMLVideoElement
	barElement?: HTMLDivElement
	volumeElement?: HTMLDivElement
}

class Video extends React.Component<VideoProps, VideoState> {
	constructor(props: VideoProps) {
		super(props);
		const lsVolume = parseFloat(window.localStorage.getItem("video_volume") || "1");
		this.state = {
			playing: !this.isMobile(),
			muted: window.localStorage.getItem("video_audio") != "1",
			volume: lsVolume >= 0 && lsVolume <= 1 ? lsVolume : 1,
			progress: 0,
		};
	}

	isMobile() {
		return navigator.userAgent.match(/mobile|iphone|ipad|android/i);
	}

	componentDidMount() {
		const containerElement = (ReactDOM.findDOMNode(this) as HTMLDivElement);
		const videoElement = containerElement.firstChild as HTMLVideoElement;
		this.setState({
			containerElement: containerElement,
			videoTimer: setInterval(this.updateTime, 10),
			videoElement: videoElement,
			barElement: containerElement.getElementsByClassName("video-progress-bar")[0] as HTMLDivElement,
			volumeElement: containerElement.getElementsByClassName("volume-control")[0] as HTMLDivElement,
		});
		videoElement.volume = this.state.volume;
		document.addEventListener("keydown", this.onKeyDown);
	}

	componentWillUnmount() {
		clearInterval(this.state.videoTimer);
		document.removeEventListener("keydown", this.onKeyDown);
	}

	onKeyDown = (event: KeyboardEvent) => {
		if (document.activeElement && ["INPUT", "SELECT", "TEXTAREA"].indexOf(document.activeElement.nodeName) > -1) {
			return;
		}
		if (event.key === 'm' || event.key === 'q') {
			// mute
			this.setState({ muted: !this.state.muted });
		}
	}

	togglePlayPause = () => {
		this.setState({ playing: !this.state.playing }, () => {
			const element = ((ReactDOM.findDOMNode(this) as Element).firstChild as HTMLVideoElement);
			if (this.state.playing) {
				element.play();
			} else {
				element.pause();
			}
		});
	}

	toggleVolume = () => {
		this.setState({ muted: !this.state.muted });
	}

	updateTime = () => {
		if (!this.state.videoElement || !this.state.barElement) {
			return;
		}
		const percentage = (100 / this.state.videoElement.duration) * this.state.videoElement.currentTime;
		this.state.barElement.style.width = percentage + "%";
	}

	onProgressClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (!this.state.barElement || !this.state.videoElement) {
			return;
		}
		const rect = (this.state.barElement.parentElement as HTMLDivElement).getBoundingClientRect() as DOMRect;
		if (rect.x && rect.width) {
			const percentage = (event.clientX - rect.x) / rect.width;
			this.state.videoElement.currentTime = this.state.videoElement.duration * percentage;
		}
	}

	onVolumeMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (!this.state.volumeElement || !this.state.videoElement || event.buttons != 1) {
			return;
		}
		const rect = (this.state.volumeElement as HTMLDivElement).getBoundingClientRect() as DOMRect;
		if (rect.x && rect.width) {
			const percentage = (event.clientX - rect.x) / rect.width;
			this.state.videoElement.volume = percentage;
			if (this.state.videoElement.muted) {
				this.state.videoElement.muted = false;
			}
		}
	}

	onVolumeChange = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
		const el = (event.currentTarget as HTMLVideoElement);
		this.setState({
			muted: el.muted,
			volume: el.volume,
		});
	}

	componentDidUpdate(prevProps: VideoProps, prevState: VideoState) {
		window.localStorage.setItem("video_audio", this.state.muted ? "0" : "1");
		window.localStorage.setItem("video_volume", this.state.volume.toString());
	}

	render() {
		const volume = this.state.muted ? 0 : this.state.volume;

		return (
			<div className="video-container" style={{
				maxHeight: this.props.maxHeight ? this.props.maxHeight + "px" : undefined,
				maxWidth: this.props.maxWidth ? this.props.maxWidth + "px" : undefined,
			}}>
				<video
					src={this.props.src}
					datatype={this.props.datatype}
					autoPlay={this.state.playing}
					loop
					muted={this.state.muted}
					preload="metadata"
					playsInline
					onVolumeChange={this.onVolumeChange} />
				<div className="video-controls">
					<div onClick={this.togglePlayPause}>
						<FontAwesomeIcon icon={this.state.playing ? faPause : faPlay} fixedWidth />
					</div>
					<div onClick={this.toggleVolume}>
						<FontAwesomeIcon icon={this.state.muted ? faVolumeMute : (this.state.volume == 0 ? faVolumeOff : (this.state.volume < 0.5 ? faVolumeDown : faVolumeUp))} fixedWidth />
					</div>
					<div className="volume-control" onMouseDown={this.onVolumeMove} onMouseMove={this.onVolumeMove}>
						<div className="filled" style={{ width: (volume * 100) + "%" }}></div>
					</div>
				</div>
				<div className="video-progress" onClick={this.onProgressClick}>
					<div className="video-progress-bar"></div>
				</div>
			</div>
		);
	}
}

export default Video;