import React from "react";
import { withAuth, IAuthHandlerValue, ProfileData, AuthHandler } from "components/Auth";
import Axios from "axios";
import moment = require("moment");
import richtext from "utils/richtext";
import { Link, RouteComponentProps } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faExclamationTriangle, faReply, faTrash } from "@fortawesome/free-solid-svg-icons";

interface IMessage {
	id: string
	sender: string
	receiver: string
	sent_timestamp: number
	subject: string
	body: string
}

interface IInbox {
	messages: IMessage[]
}

interface MessagesProps extends IAuthHandlerValue, RouteComponentProps {
}

interface MessagesState {
	loading: boolean
	error?: string
	inbox?: IInbox
}

class Messages extends React.Component<MessagesProps, MessagesState> {
	constructor(props: MessagesProps) {
		super(props);
		this.state = {
			loading: true,
		}
	}

	componentDidUpdate(prevProps: MessagesProps) {
		if (prevProps.location.key !== this.props.location.key) {
			this.fetchInfo();
		}
	}

	fetchInfo() {
		this.setState({ loading: true, error: undefined });
		Axios
			.get("/api/user/inbox")
			.then(res => this.setState({ loading: false, inbox: res.data }))
			.catch(err => {
				this.setState({
					loading: false,
					error: err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Irgendwas am Server ist gerade doof",
				});
			});
	}

	componentDidMount() {
		document.title = "Nachrichten | bilderbrett";
		this.fetchInfo();
	}

	onDelete = (id: string) => {
		return new Promise<any>((resolve, reject) => {
			Axios
				.post("/api/user/delete-message", { id: id })
				.then(res => {
					const inbox = this.state.inbox as IInbox;
					inbox.messages = inbox.messages.filter(m => m.id !== id);
					this.setState({ inbox: inbox });
					resolve(res);
					(this.props.authHandler as AuthHandler).refresh();
				})
				.catch(err => {
					reject(err);
				})
		});
	}

	render() {
		return (
			<>
				<div className="card">
					<div className="header">
						<div className="title">Nachrichten</div>
						<div className="options">
							{this.state.loading
								? <FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
								: null
							}
							{this.state.error
								? <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
								: null
							}
						</div>
					</div>
					{this.state.error
						? <div className="content"><div className="error">{this.state.error}</div></div>
						: null
					}
				</div>
				<TransitionGroup>
					{this.state.inbox && this.state.inbox.messages.map((el: any) => (
						<CSSTransition key={el.id} timeout={700} exit classNames="card-transition">
							<Message
								self_name={(this.props.profile as ProfileData).name}
								message={el}
								onDelete={this.onDelete} />
						</CSSTransition>
					))}
				</TransitionGroup>
			</>
		);
	}
}

interface MessageProps {
	self_name: string
	message: IMessage
	onDelete: (id: string) => Promise<any>
}

interface MessageState {
	loading: boolean
	error?: string
}

class Message extends React.Component<MessageProps, MessageState> {
	constructor(props: MessageProps) {
		super(props);
		this.state = { loading: false };
	}

	checkUserLink = (v: string) => {
		if (v == "bilderbrett.de") {
			return v;
		}
		return <Link to={"/user/" + v}>{v}</Link>
	}

	onDelete = (event: any) => {
		this.setState({ loading: true, error: undefined });

		const node = (ReactDOM.findDOMNode(this) as any);
		node.style.height = node.getBoundingClientRect().height + "px";
		console.log(node);

		this.props
			.onDelete(this.props.message.id)
			.catch(err => {
				this.setState({
					loading: false,
					error: err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Irgendwas am Server ist gerade doof"
				});
			})
	}

	render() {
		const { message, self_name } = this.props;

		return (
			<div className="card">
				<div className="header">
					<div className="title">{message.subject}</div>
					<div className="options">
						{this.state.loading
							? <FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
							: null
						}
						{this.state.error
							? <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
							: null
						}
					</div>
				</div>
				<div className="content">{richtext.formatText(message.body)}</div>
				<div className="footer">
					{message.sender == self_name
						? <div>gesendet an {this.checkUserLink(message.receiver)} {moment(message.sent_timestamp / 1000000).format("[am] D.M.YYYY [um] H:mm")}</div>
						: <div>gesendet von {this.checkUserLink(message.sender)} {moment(message.sent_timestamp / 1000000).format("[am] D.M.YYYY [um] H:mm")}</div>
					}
					<br />
					<div className="buttons">
						{(message.sender != "bilderbrett.de" && message.sender != self_name)
							? <button disabled={this.state.loading}>Antworten <FontAwesomeIcon icon={faReply} fixedWidth /></button>
							: null
						}
						<button disabled={this.state.loading} className="passive" onClick={this.onDelete}>Löschen <FontAwesomeIcon icon={faTrash} fixedWidth /></button>
					</div>
					{this.state.error
						? <><br /><div className="error">{this.state.error}</div></>
						: null
					}
				</div>
			</div>
		)
	}
}

export default withAuth(Messages);