import React from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends React.Component<any> {
	componentDidUpdate(prevProps: {location: string}) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		return this.props.children ? this.props.children : null;
	}
}

export default withRouter(ScrollToTop)
