import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faCircle } from "@fortawesome/free-solid-svg-icons"

interface IRadioProps {
	name: string
	on: boolean
	onChange: (name: string, on: boolean) => void
	disabled?: boolean
}

class Radio extends React.Component<IRadioProps> {
	constructor(props: IRadioProps) {
		super(props)
	}

	onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (this.props.disabled) {
			return
		}
		this.props.onChange(this.props.name, !this.props.on)
	}

	render() {
		return (
			<div className={"radio"+(this.props.on?" on":" off")+(this.props.disabled?" disabled":"")} onClick={this.onClick}>
				<div className="inner">
					{this.props.on
						? <FontAwesomeIcon icon={faCircle} />
						: null
					}
				</div>
			</div>
		)
	}
}

export default Radio