import React from "react";
import { Link } from "react-router-dom";

const buildReplaceText = (split_regex: RegExp, prefix: string, cb: (index: number, value: string, reduced: string) => React.ReactNode) => (nodes: React.ReactNode[]) => {
	const result: React.ReactNode[] = [];
	nodes.forEach((node, index) => {
		if (typeof node === "string") {
			result.push(...node.split(split_regex).map((value, index) => {
				if (split_regex.test(value)) {
					return cb(index, value, value.substring(prefix.length));
				}
				return value;
			}));
			return;
		}
		result.push(node);
	});
	return result;
};

const replaceExternalLinks = buildReplaceText(
	/(https?:\/\/\S*)/g,
	"http",
	(index, value, reduced) => <a href={value} key={"replaceExternalLinks("+index+")"}>{reduced.startsWith("s://")?reduced.substring(4):reduced.substring(3)}</a>
);

const replaceInternalLinks = buildReplaceText(
	/(https:\/\/bilderbrett.de\S*)/g,
	"https://bilderbrett.de",
	(index, value, reduced) => <Link to={reduced?reduced:"/"} key={"replaceInternalLinks("+index+")"}>{reduced?reduced:"bilderbrett.de"}</Link>
);

const replaceUserTags = buildReplaceText(
	/(@[A-z0-9]+)/g,
	"@",
	(index, value, reduced) => <Link to={"/user/"+reduced} key={"replaceUserTags("+index+")"}>{value}</Link>
);

const formatText = (text: string) => {
	const result: React.ReactNode[] = [];
	const lines = text.split("\n");
	lines.forEach((line, index) => {
		if (result.length > 0) {
			result.push(<br key={"br"+index} />);
		}
		if (line.length > 0) {
			result.push(replaceUserTags(replaceExternalLinks(replaceInternalLinks([line]))));
		}
	});
	return result;
};

export default {
	buildReplaceText,
	replaceExternalLinks,
	replaceInternalLinks,
	replaceUserTags,
	formatText,
}