import React from "react"
import { AsyncCreatable, Option, OptionValues, AutocompleteResult } from "react-select"
import Axios from "axios"

interface ITagsSelectProps {
	values: string[]
	filterValues?: string[]
	onChange: (values: string[]) => void
	disabled?: boolean
}

class TagsSelect extends React.Component<ITagsSelectProps> {
	shouldComponentUpdate(nextProps: Readonly<ITagsSelectProps>) {
		return JSON.stringify([this.props.values, this.props.filterValues, this.props.disabled])
			!== JSON.stringify([nextProps.values, nextProps.filterValues, nextProps.disabled])
	}

	promptTextCreator = (label: string) => {
		return "\"" + label + "\" hinzufügen?"
	}

	loadOptions = (value: string) => {
		return new Promise<AutocompleteResult>((resolve, reject) => {
			Axios
				.post("/api/images/tag-search", { q: value })
				.then(res => {
					const options: Option<OptionValues>[] = []
					res.data.tags.forEach((t: string) => options.push({ value: t, label: t }))
					resolve({
						options: options,
						complete: res.data.complete,
					})
				})
				.catch(err => reject(err))
		})
	}

	filterOptions = (options: Option<OptionValues>[], filter: string, currentValues: Option<OptionValues>[]) => {
		const filterValues = this.props.filterValues || []
		return options.filter(opt => {
			const value = opt.value as string
			for (let i = 0; i < currentValues.length; i++) {
				if (currentValues[i].value == value) {
					return false
				}
			}
			return filterValues.indexOf(value) < 0
		})
	}

	onChange = (value: Option<OptionValues> | Option<OptionValues>[] | null) => {
		const tags: string[] = []
		if (value !== null) {
			(value as Option<OptionValues>[]).forEach(el => {
				tags.push(el.value as string)
			})
		}
		this.props.onChange(tags)
	}

	shouldKeyDownEventCreateNewOption = (arg: { keyCode: number }) => {
		return (
			[
				9,   // tab
				13,  // enter
				190, // .
			].indexOf(arg.keyCode) !== -1
		)
	}

	render() {
		const values: Option<OptionValues>[] = this.props.values.map(el => ({ value: el, label: el }))

		return (
			<AsyncCreatable
				addLabelText='"{label}" hinzufügen?'
				promptTextCreator={this.promptTextCreator}
				loadingPlaceholder="eben den Server befragen..."
				placeholder="Marken hinzufügen..."
				noResultsText="nichts dabei"
				backspaceToRemoveMessage='Rücktaste um {last label} zu entfernen'
				searchPromptText="Suchen..."
				clearAllText="alle entfernen"
				clearValueText="entfernen"
				multi
				value={values}
				onChange={this.onChange}
				loadOptions={this.loadOptions}
				disabled={this.props.disabled}
				filterOptions={this.filterOptions}
				shouldKeyDownEventCreateNewOption={this.shouldKeyDownEventCreateNewOption} />
		)
	}
}

export default TagsSelect