import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./Auth";

const PrivateRoute = ({ component: Component, require_mod, ...props }: { component: any, require_mod?: boolean, [key:string]: any }) => (
	<AuthContext.Consumer>
		{value =>
			<Route {...props} render={props => (
				value.profile
					? (
						require_mod && !value.profile.mod
							? <Redirect to={{ pathname: "/" }} />
							: <Component {...props} />
					)
					: <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
			)} />
		}
	</AuthContext.Consumer>
)

export default PrivateRoute;