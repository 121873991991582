import React, { FormEvent } from "react";
import { RouteComponentProps } from "react-router";
import Axios from "axios";
import moment from "moment";
import TextareaAutosize from "react-autosize-textarea";
import { withAuth, IAuthHandlerValue } from "components/Auth";
import { Link } from "react-router-dom";
import richtext from "utils/richtext";
import Video from "components/Video";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faPlusCircle, faMinusCircle, faSyncAlt, faAngleRight, faCloudUploadAlt, faReply } from "@fortawesome/free-solid-svg-icons";
import TagsSelect from "components/TagsSelect";

interface Tag {
	text: string
	karma: number
	voted_karma: number
}

interface IComment {
	id: string
	user: string
	op: boolean
	timestamp: number
	comment: string
	karma: number
	voted_karma: number
	parent_id?: string
}

interface ImageFullInfo {
	id: string
	timestamp: number
	uploader: string
	ext: string
	karma: number
	voted_karma: number
	next_newer_id?: string
	next_older_id?: string
	tags: Tag[]
	is_favorite: boolean
	comments: IComment[]
	image_url: string
	thumbnail_url: string
	users: UserMap
	Width: number
	Height: number
}

interface IItemState {
	info?: ImageFullInfo
	zoomed: boolean
	add_tags: string[]
	loading_tags: boolean
	loading_comment: boolean
	touch_start?: {
		id: number
		x: number
		y: number
	}
	comment: string
}

interface UserShortInfo {
	name: string
	category: string
	karma: number
	color: string
}

type UserMap = { [key: string]: UserShortInfo };

interface ItemRouteParams {
	id: string
	username?: string
	gallery?: string
}

interface ItemProps extends RouteComponentProps<ItemRouteParams>, IAuthHandlerValue {
}

class Item extends React.Component<ItemProps, IItemState> {
	constructor(props: ItemProps) {
		super(props);
		this.state = {
			zoomed: false,
			add_tags: [],
			loading_tags: false,
			loading_comment: false,
			comment: "",
		};
	}

	id = () => {
		return this.props.match.params.id;
	}

	isLoading = () => {
		return this.state.loading_tags || this.state.loading_comment;
	}

	fetchInfo = () => {
		document.title = "... | bilderbrett";
		if (this.state.info) {
			const info = this.state.info;
			info.next_newer_id = undefined;
			info.next_older_id = undefined;
			this.setState({ info: this.state.info, add_tags: [], comment: "" });
		}
		Axios
			.get(this.buildURL("/api/images/by-id/" + this.id(), {
				search: this.buildSearch(),
				nsfw: localStorage.getItem("nsfw") == "1" ? "1" : "0",
			}))
			.then(res => {
				this.setState({ info: res.data, zoomed: false });
				if (res.data.tags.length > 0) {
					document.title = "\"" + res.data.tags[0].text + "\" | bilderbrett";
				} else {
					document.title = "\"" + this.id() + "\" | bilderbrett";
				}
			})
			.catch(err => console.log(err));
	}

	buildSearch = () => {
		const { params } = this.props.match;
		if (params.username && params.gallery) {
			return params.username + ":" + params.gallery;
		}
	}

	buildURL = (path: string, obj: { [key: string]: any }) => {
		let result: string[] = [];
		Object.keys(obj).forEach(k => {
			if (obj[k] == undefined) {
				return;
			}
			result.push(encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]));
		});
		return path + (result.length > 0 ? "?" + result.join("&") : "");
	}

	onVote = (event: any) => {
		if (!this.state.info) {
			return;
		}
		let newKarma = 0;
		switch (true) {
			case event.currentTarget.classList.contains("vote-plus"):
				newKarma = 1;
				break;
			case event.currentTarget.classList.contains("vote-minus"):
				newKarma = -1;
				break;
		}
		this.updateKarma(this.state.info.voted_karma == newKarma ? 0 : newKarma);
	}

	updateKarma = (value: number) => {
		const id = this.id();
		Axios.post("/api/images/by-id/" + id + "/karma", { karma: value })
			.then(res => {
				if (this.state.info && this.state.info.id === id) {
					const info = this.state.info;
					info.karma = res.data.karma;
					info.voted_karma = res.data.voted_karma;
					this.setState({ info: info });
				}
			})
			.catch(err => console.log(err));
	}

	onFavorite = (event: any) => {
		if (!this.state.info) {
			return;
		}
		const id = this.id();
		Axios
			.post("/api/user/favorite", { image_id: id, is_favorite: !this.state.info.is_favorite })
			.then(res => {
				if (this.state.info && this.state.info.id === id) {
					const info = this.state.info;
					info.is_favorite = !info.is_favorite;
					this.setState({ info: info });
				}
			})
			.catch(err => console.log(err));
	}

	onTagVote = (tag: Tag) => (event: any) => {
		if (!this.state.info) {
			return;
		}
		let newKarma = 0;
		switch (true) {
			case event.currentTarget.classList.contains("vote-plus"):
				newKarma = 1;
				break;
			case event.currentTarget.classList.contains("vote-minus"):
				newKarma = -1;
				break;
		}
		this.updateTagKarma(tag.text, tag.voted_karma == newKarma ? 0 : newKarma);
	}

	updateTagKarma = (tag: string, value: number) => {
		const id = this.id();
		Axios.post("/api/images/by-id/" + id + "/tag-karma", { tag: tag, karma: value })
			.then(res => {
				if (this.state.info && this.state.info.id === id) {
					const info = this.state.info;
					for (let i = 0; i < info.tags.length; i++) {
						if (info.tags[i].text === tag) {
							info.tags[i].karma = res.data.karma;
							info.tags[i].voted_karma = res.data.voted_karma;
							break;
						}
					}
					this.setState({ info: info });
				}
			})
			.catch(err => console.log(err));
	}

	onKeyDown = (event: KeyboardEvent) => {
		if (event.key === "Escape") {
			if (document.activeElement && ["INPUT", "SELECT", "TEXTAREA"].indexOf(document.activeElement.nodeName) > -1) {
				(document.activeElement as any).blur();
				return;
			}
			this.props.history.push("/", { image_id: this.id() });
		}

		if (!this.state.info) {
			return;
		}

		if (document.activeElement && ["INPUT", "SELECT", "TEXTAREA"].indexOf(document.activeElement.nodeName) > -1) {
			return;
		}

		if (event.key === "ArrowLeft" && this.state.info.next_newer_id && !this.isLoading()) {
			// navigate to next newer
			this.props.history.push(this.state.info.next_newer_id);

		} else if (event.key === "ArrowRight" && this.state.info.next_older_id && !this.isLoading()) {
			// navigate to next older
			this.props.history.push(this.state.info.next_older_id);

		} else if (event.key === 'ArrowUp' || event.key === "+") {
			// karma +
			this.updateKarma(this.state.info.voted_karma == 1 ? 0 : 1);

		} else if (event.key === 'ArrowDown' || event.key === "-") {
			// karma -
			this.updateKarma(this.state.info.voted_karma == -1 ? 0 : -1);

		} else if (event.key === "z") {
			// zoom
			this.setState({ zoomed: !this.state.zoomed });

		} else if (event.key === "f") {
			// favorite
			this.onFavorite(event);

		}
	}

	onTouchStart = (event: TouchEvent) => {
		if (this.state.touch_start) {
			return;
		}
		this.setState({ touch_start: { id: event.touches[0].identifier, x: event.touches[0].pageX, y: event.touches[0].pageY } });
	}

	onTouchEnd = (event: TouchEvent) => {
		if (!this.state.touch_start) {
			return;
		}
		if (event.changedTouches.length == 0) {
			return;
		}
		for (let i = 0; i < event.changedTouches.length; i++) {
			if (event.changedTouches[i].identifier == this.state.touch_start.id) {
				const delta_x = event.changedTouches[i].pageX - this.state.touch_start.x;
				const delta_y = event.changedTouches[i].pageY - this.state.touch_start.y;

				if (document.activeElement && document.activeElement.nodeName === "INPUT" || this.state.zoomed) {
					this.setState({ touch_start: undefined });
					return;
				}

				if (!this.state.info) {
					this.setState({ touch_start: undefined });
					return;
				}

				if (Math.abs(delta_x) > Math.abs(delta_y) && Math.abs(delta_y) < 40) {
					if (delta_x > 50 && this.state.info.next_newer_id && !this.isLoading()) {
						this.props.history.push(this.state.info.next_newer_id);
					} else if (delta_x < -50 && this.state.info.next_older_id && !this.isLoading()) {
						this.props.history.push(this.state.info.next_older_id);
					}
				}
				this.setState({ touch_start: undefined });
				break;
			}
		}
	}

	scrollToCommentFromHash = () => {
		const rex = this.props.location.hash.match(/^\#comment-(.+)$/)
		if (rex && rex[1]) {
			let connected = false;

			const id = rex[1];

			const reset = () => {
				if (connected) {
					observer.disconnect();
					connected = false;
				}
			}

			const scrollToComment = () => {
				const el = document.getElementById("comment-" + id);
				if (!el) {
					return false;
				}
				el.scrollIntoView();
				reset();
				return true;
			};

			if (scrollToComment()) {
				return;
			}

			const observer: MutationObserver = new MutationObserver(scrollToComment);
			observer.observe(document, {
				attributes: true,
				childList: true,
				subtree: true,
			});
			connected = true;
			window.setTimeout(() => reset(), 1000);
		}
	}

	componentDidUpdate(prevProps: ItemProps, prevState: IItemState, snapshot: any) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.fetchInfo();
		}
		if (this.props.location.hash !== prevProps.location.hash) {
			this.scrollToCommentFromHash();
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", this.onKeyDown);
		document.addEventListener("touchstart", this.onTouchStart);
		document.addEventListener("touchend", this.onTouchEnd);
		this.fetchInfo();
		this.scrollToCommentFromHash();
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKeyDown);
		document.removeEventListener("touchstart", this.onTouchStart);
		document.removeEventListener("touchend", this.onTouchEnd);
	}

	toggleZoom = (event: any) => {
		this.setState({ zoomed: !this.state.zoomed });
	}

	onChangeTags = (values: string[]) => {
		this.setState({ add_tags: values });
	}

	onTagsSubmit = (event: FormEvent) => {
		event.preventDefault();
		this.setState({ loading_tags: true });
		const id = this.id();
		Axios
			.post("/api/images/by-id/" + id + "/tags", { tags: this.state.add_tags })
			.then(res => {
				if (this.state.info && this.state.info.id == id) {
					const info = this.state.info;
					info.tags = res.data.tags;
					this.setState({ info: info, loading_tags: false, add_tags: [] });
				}
			})
			.catch(err => console.log(err));
	}

	onChangeComment = (event: any) => {
		this.setState({ comment: event.currentTarget.value });
	}

	postComment = (comment: string, parent_id: string) => {
		const id = this.id();
		return new Promise((resolve, reject) => {
			Axios
				.post("/api/images/by-id/" + id + "/comments", { comment: comment, parent_id: parent_id })
				.then(res => {
					if (this.state.info && this.state.info.id == id) {
						const info = this.state.info;
						info.comments = res.data.comments;
						info.users = res.data.users;
						this.setState({ info: info });
						this.props.history.push(this.props.location.pathname + "#comment-" + res.data.id);
						resolve(res.data);
					}
				})
				.catch(err => reject(err));
		});
	}

	onCommentSubmit = (event: FormEvent) => {
		event.preventDefault();
		const comment = this.state.comment.trim();
		if (comment.length == 0) return;
		this.setState({ loading_comment: true });
		this
			.postComment(comment, "")
			.then(res => this.setState({ loading_comment: false, comment: "" }))
			.catch(err => {
				console.log(err);
				this.setState({ loading_comment: false, comment: "" });
			});
	}

	onCommentSetKarma = (comment_id: string, karma: number) => {
		const id = this.id();
		Axios.post("/api/images/by-id/" + id + "/comment-karma", { comment_id: comment_id, karma: karma })
			.then(res => {
				if (this.state.info && this.state.info.id === id) {
					const info = this.state.info;
					for (let i = 0; i < info.comments.length; i++) {
						if (info.comments[i].id === comment_id) {
							info.comments[i].karma = res.data.karma;
							info.comments[i].voted_karma = res.data.voted_karma;
							break;
						}
					}
					this.setState({ info: info });
				}
			})
			.catch(err => console.log(err));
	}

	render() {
		if (!this.state.info) {
			return (
				<div className="detail-view">
					<div className="sidebar">
					</div>
					<div className="image">
					</div>
				</div>
			);
		}

		const { info } = this.state;
		const uploaded = moment(info.timestamp / 1000000);

		const hash = this.props.location.hash

		const userStyle = info.users[info.uploader]
			? { color: info.users[info.uploader].color }
			: {};

		return (
			<>
				<div className="detail-view">
					<div className="sidebar">
						<div>hochgeladen von <Link to={"/user/" + info.uploader} style={userStyle}>{info.uploader}</Link> {uploaded.format("[am] D.M.YYYY [um] H:mm")}</div>
						<br />
						<h3 style={{ display: "flex", alignItems: "center", marginBottom: "0" }}>
							<span>Karma:</span>
							<span style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem", flexGrow: 1 }}>{info.karma}</span>
							<div className="vote">
								<div className={"favorite action" + (info.is_favorite ? " voted" : "")} onClick={this.onFavorite}>
									<FontAwesomeIcon icon={faHeart} />
								</div>
								<div className={"vote-plus action" + (info.voted_karma == 1 ? " voted" : "")} onClick={this.onVote}>
									<FontAwesomeIcon icon={faPlusCircle} />
								</div>
								<div className={"vote-minus action" + (info.voted_karma == -1 ? " voted" : "")} onClick={this.onVote}>
									<FontAwesomeIcon icon={faMinusCircle} />
								</div>
							</div>
						</h3>
						<div className="tags">
							{info.tags.map(tag => (
								<div key={tag.text} className={tag.karma < 0 ? "bad" : ""}>
									<span>{tag.text}</span>
									<div className="vote">
										<span className={tag.voted_karma == 1 ? "action vote-plus voted" : "action vote-plus"} onClick={this.onTagVote(tag)}>+</span>
										<span className={tag.voted_karma == -1 ? "action vote-minus voted" : "action vote-minus"} onClick={this.onTagVote(tag)}>−</span>
									</div>
								</div>
							))}
						</div>
						<form onSubmit={this.onTagsSubmit}>
							<TagsSelect
								values={this.state.add_tags}
								onChange={this.onChangeTags}
								disabled={this.state.loading_tags}
								filterValues={this.state.info.tags.map(el => el.text)}
							/>
							<div className="vertical-spacing" />
							<button type="submit" disabled={this.state.loading_tags}>
								Markieren <FontAwesomeIcon icon={this.state.loading_tags ? faSyncAlt : faAngleRight} spin={this.state.loading_tags} fixedWidth />
							</button>
						</form>
						<div className="splitter" />
						<form onSubmit={this.onCommentSubmit}>
							<TextareaAutosize
								className="new-comment"
								placeholder="Kommentar schreiben..."
								value={this.state.comment}
								onChange={this.onChangeComment}
								disabled={this.state.loading_comment} />
							<div className="vertical-spacing" />
							<button type="submit" disabled={this.state.loading_comment}>
								Hinzufügen <FontAwesomeIcon icon={this.state.loading_comment ? faSyncAlt : faAngleRight} spin={this.state.loading_comment} fixedWidth />
							</button>
						</form>
						<div className="vertical-spacing" />
						{this.state.info.comments.filter(comment => !comment.parent_id).map(comment => <CommentComp key={comment.id}
							comment={comment}
							comments={(this.state.info as ImageFullInfo).comments}
							color={0}
							onSetKarma={this.onCommentSetKarma}
							hash={hash}
							onReply={this.postComment}
							users={(this.state.info as ImageFullInfo).users}
						/>)}
					</div>
					<div className={this.state.zoomed ? "image zoomed" : "image"}>
						{info.image_url.endsWith(".mp4")
							? <Video
								src={"/images/" + info.image_url}
								maxHeight={this.state.info.Height} />
							: <img src={"/images/" + info.image_url} />
						}
					</div>
				</div>
			</>
		);
	}
}

interface CommentCompState {
	comment: string
	loading_comment: boolean
	comment_shown: boolean
}

interface CommentCompProps {
	comment: IComment
	comments: IComment[]
	color: number
	onSetKarma: (comment_id: string, karma: number) => void
	hash: string
	onReply: (comment: string, parent_id: string) => Promise<any>
	users: UserMap
}

class CommentComp extends React.Component<CommentCompProps, CommentCompState> {
	constructor(props: CommentCompProps) {
		super(props);
		this.state = {
			comment: "",
			loading_comment: false,
			comment_shown: false,
		};
	}

	onVote = (event: any) => {
		let newKarma = 0;
		switch (true) {
			case event.currentTarget.classList.contains("vote-plus"):
				newKarma = 1;
				break;
			case event.currentTarget.classList.contains("vote-minus"):
				newKarma = -1;
				break;
		}
		this.props.onSetKarma(this.props.comment.id, this.props.comment.voted_karma == newKarma ? 0 : newKarma);
	}

	onChangeComment = (event: any) => {
		this.setState({ comment: event.currentTarget.value });
	}

	onCommentSubmit = (event: FormEvent) => {
		event.preventDefault();
		const comment = this.state.comment.trim();
		if (comment.length == 0) return;
		this.setState({ loading_comment: true });
		const id = this.props.comment.id;
		this.props
			.onReply(this.state.comment, id)
			.then(res => {
				if (this.props.comment.id === id) {
					this.setState({ comment: "", loading_comment: false, comment_shown: false });
				}
			})
			.catch(err => {
				console.log(err);
				if (this.props.comment.id === id) {
					this.setState({ comment: "", loading_comment: false, comment_shown: false });
				}
			});
	}

	toggleReply = (event: any) => {
		this.setState({ comment_shown: !this.state.comment_shown });
	}

	replaceUserTags = (text: string) => {
		const parts: JSX.Element[] = [];
		text.split(/(@[A-z0-9]+)/).forEach(el => {
			if (el.startsWith("@")) {
				parts.push(<Link to={"/user/" + el.substring(1)}>{el}</Link>);
			} else {
				parts.push(<>{el}</>);
			}
		});
		return parts;
	}

	render() {
		const { comment } = this.props;

		const childColor = this.props.color == 4 ? 0 : this.props.color + 1;
		const children = this.props.comments.filter(el => el.parent_id == comment.id);

		const userStyle = this.props.users[comment.user]
			? { color: this.props.users[comment.user].color }
			: {};

		return (
			<div className="comment" id={"comment-" + comment.id}>
				<div className={"details col-" + this.props.color + (this.props.hash === "#comment-" + comment.id ? " highlight" : "")}>
					<div>{richtext.formatText(comment.comment)}</div>
					<div className="bottom">
						<div>
							{comment.op
								? <FontAwesomeIcon icon={faCloudUploadAlt} />
								: null
							}
							<span>
								<Link to={"/user/" + comment.user} style={userStyle}>{comment.user}</Link>
							</span>
							<br />
							<Link className="datetime" to={"#comment-" + comment.id}>{moment(comment.timestamp / 1000000).format("[am] D.M.YYYY [um] H:mm")}</Link>
						</div>
						<div className="action" onClick={this.toggleReply}>
							<FontAwesomeIcon icon={faReply} />
						</div>
						<span>{comment.karma}</span>
						<div className="vote">
							<span className={comment.voted_karma == 1 ? "action vote-plus voted" : "action vote-plus"} onClick={this.onVote}>+</span>
							<span className={comment.voted_karma == -1 ? "action vote-minus voted" : "action vote-minus"} onClick={this.onVote}>−</span>
						</div>
					</div>
					{this.state.comment_shown
						? (
							<div className="comment-reply">
								<form onSubmit={this.onCommentSubmit}>
									<TextareaAutosize
										className="new-comment"
										placeholder="Antwort schreiben..."
										value={this.state.comment}
										onChange={this.onChangeComment}
										disabled={this.state.loading_comment}
										autoFocus />
									<div className="vertical-spacing" />
									<button type="submit" disabled={this.state.loading_comment}>
										Antworten <FontAwesomeIcon icon={this.state.loading_comment ? faSyncAlt : faAngleRight} spin={this.state.loading_comment} fixedWidth />
									</button>
									<button type="button" className="passive" disabled={this.state.loading_comment} onClick={this.toggleReply}>
										Abbrechen
									</button>
								</form>
							</div>
						)
						: null
					}
				</div>
				{children.length > 0
					? (
						<div className="children">
							{children.map(child_comment => <CommentComp key={comment.id + "--" + child_comment.id}
								comment={child_comment}
								comments={this.props.comments}
								color={childColor}
								onSetKarma={this.props.onSetKarma}
								hash={this.props.hash}
								onReply={this.props.onReply}
								users={this.props.users}
							/>)}
						</div>
					)
					: null
				}
			</div>
		);
	}
}

export default withAuth(Item);