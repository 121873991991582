import React from "react";
import { RouteComponentProps, Redirect } from "react-router";
import Axios from "axios";
import { withAuth, IAuthHandlerValue } from "components/Auth";

import NotFound from "./NotFound";
import InternalError from "./InternalError";
import { Link } from "react-router-dom";
import moment = require("moment");
import richtext from "utils/richtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

interface UserProps extends RouteComponentProps<{ name: string }>, IAuthHandlerValue {
}

interface ImageInfo {
	id: string
	timestamp: number
	uploader: string
	ext: string
	karma: number
	voted_karma: number
	image_url: string
	thumbnail_url: string
}

interface IComment {
	ID: string
	ImageID: string
	CreatedNanoTS: number
	Comment: string
	ThumbnailURL: string
	Karma: number
}

interface UserState {
	loading: boolean
	info?: ProfileData
	error?: number
	loading_uploads: boolean
	error_uploads?: string
	uploads: ImageInfo[]
	loading_favorites: boolean
	error_favorites?: string
	favorites: ImageInfo[]
	loading_comments: boolean
	error_comments?: string
	comments: IComment[]
}

interface ProfileData {
	name: string
	karma: number
	category: string
	color: string
	mod?: boolean
	joined_timestamp: number
}

class User extends React.Component<UserProps, UserState> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: true,
			loading_uploads: true,
			uploads: [],
			loading_favorites: true,
			favorites: [],
			loading_comments: true,
			comments: [],
		};
	}

	fetchInfo = () => {
		const { name } = this.props.match.params;
		document.title = "Profil von " + name + " | bilderbrett";
		this.setState({ loading: true, info: undefined, error: undefined });
		Axios
			.get<ProfileData>("/api/user/by-name/" + name)
			.then(res => {
				if (this.props.match.params.name !== name) {
					return;
				}
				this.setState({ info: res.data, loading: false });
			})
			.catch(err => {
				if (this.props.match.params.name !== name) {
					return;
				}
				this.setState({ error: err.response.status, loading: false });
			});
	}

	fetchUploads = () => {
		const { name } = this.props.match.params;
		this.setState({
			loading_uploads: true,
			error_uploads: undefined,
			uploads: [],
		});
		Axios
			.get<{ images: ImageInfo[] }>(this.buildURL("/api/images", {
				search: name + ":uploads",
				limit: 10,
				nsfw: localStorage.getItem("nsfw") == "1" ? "1" : "0",
			}))
			.then(res => {
				if (this.props.match.params.name !== name) {
					return;
				}
				this.setState({
					loading_uploads: false,
					uploads: res.data.images,
				});
			})
			.catch(err => {
				if (this.props.match.params.name !== name) {
					return;
				}
				this.setState({
					loading_uploads: false,
					error_uploads: err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Irgendwas am Server ist gerade doof",
				});
			});
	}

	fetchFavorites = () => {
		const { name } = this.props.match.params;
		this.setState({
			loading_favorites: true,
			error_favorites: undefined,
			favorites: [],
		});
		Axios
			.get<{ images: ImageInfo[] }>(this.buildURL("/api/images", {
				search: name + ":favorites",
				limit: 10,
				nsfw: localStorage.getItem("nsfw") == "1" ? "1" : "0",
			}))
			.then(res => {
				if (this.props.match.params.name !== name) {
					return;
				}
				this.setState({
					loading_favorites: false,
					favorites: res.data.images,
				});
			})
			.catch(err => {
				if (this.props.match.params.name !== name) {
					return;
				}
				this.setState({
					loading_favorites: false,
					error_favorites: err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Irgendwas am Server ist gerade doof",
				});
			});
	}

	fetchComments = () => {
		const { name } = this.props.match.params;
		this.setState({
			loading_comments: true,
			error_comments: undefined,
			comments: [],
		});
		Axios
			.get<{ Comments: IComment[] }>(this.buildURL("/api/user/by-name/" + name + "/comments", {
				limit: 10,
				nsfw: localStorage.getItem("nsfw") == "1" ? "1" : "0",
			}))
			.then(res => {
				if (this.props.match.params.name !== name) {
					return;
				}
				this.setState({
					loading_comments: false,
					comments: res.data.Comments,
				});
			})
			.catch(err => {
				if (this.props.match.params.name !== name) {
					return;
				}
				this.setState({
					loading_comments: false,
					error_comments: err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Irgendwas am Server ist gerade doof",
				});
			});
	}

	buildURL = (path: string, obj: { [key: string]: any }) => {
		let result: string[] = [];
		Object.keys(obj).forEach(k => {
			if (obj[k] == undefined) {
				return;
			}
			result.push(encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]));
		});
		return path + (result.length > 0 ? "?" + result.join("&") : "");
	}

	componentDidUpdate(prevProps: UserProps) {
		if (prevProps.match.params.name !== this.props.match.params.name) {
			this.fetchInfo();
			this.fetchUploads();
			this.fetchFavorites();
			this.fetchComments();
		}
	}

	componentDidMount() {
		this.fetchInfo();
		this.fetchUploads();
		this.fetchFavorites();
		this.fetchComments();
	}

	render() {
		const { name } = this.props.match.params;

		if (this.state.loading) {
			return (
				<div className="card">
					<div className="header">
						<div className="title">{name}</div>
						<div className="options">
							<FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
						</div>
					</div>
				</div>
			);
		}

		if (this.state.error) {
			switch (this.state.error) {
				case 404:
					return <NotFound />
				default:
					return <InternalError />
			}
		}

		const info = this.state.info as ProfileData;

		if (info.name.toLowerCase() === this.props.match.params.name.toLowerCase() && info.name !== this.props.match.params.name) {
			return <Redirect to={"/user/" + info.name} />;
		}

		return (
			<>
				<div className="card">
					<div className="header">
						<div className="title" style={{ color: info.color }}>{name}</div>
						<div className="options">{info.category}</div>
					</div>
					<div className="content">
						<h3 style={{ display: "flex", alignItems: "center" }}>
							<span>Karma:</span>
							<span style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem", flexGrow: 1 }}>{info.karma}</span>
						</h3>
						<div>Dabei seit dem {moment(info.joined_timestamp / 1000000).format("D.M.YYYY")}</div>
					</div>
				</div>

				<div className="card">
					<div className="header">
						<div className="title"><Link to={"/user/" + this.props.match.params.name + "/uploads"}>Hochgeladene Bilder</Link></div>
						<div className="options">
							{this.state.loading_uploads
								? <FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
								: null
							}
							{this.state.error_uploads
								? <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
								: null
							}
						</div>
					</div>
					{!this.state.loading_uploads
						? (
							<div className="content">
								{this.state.error_uploads
									? <div className="error">{this.state.error_uploads}</div>
									: this.state.uploads.length > 0
										? (
											<div className="gallery small">
												{this.state.uploads.map(img => (
													<Link key={img.id} to={"/user/" + this.props.match.params.name + "/uploads/i/" + img.id}>
														<img src={"/images/" + img.thumbnail_url} />
													</Link>
												))}
											</div>
										)
										: <>Dieser Nutzer hat bisher noch nichts hochgeladen</>
								}
							</div>
						)
						: null
					}
				</div>

				<div className="card">
					<div className="header">
						<div className="title"><Link to={"/user/" + this.props.match.params.name + "/favorites"}>Favoriten</Link></div>
						<div className="options">
							{this.state.loading_favorites
								? <FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
								: null
							}
							{this.state.error_favorites
								? <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
								: null
							}
						</div>
					</div>
					{!this.state.loading_favorites
						? (
							<div className="content">
								{this.state.error_favorites
									? <div className="error">{this.state.error_favorites}</div>
									: this.state.favorites.length > 0
										? (
											<div className="gallery small">
												{this.state.favorites.map(img => (
													<Link key={img.id} to={"/user/" + this.props.match.params.name + "/favorites/i/" + img.id}>
														<img src={"/images/" + img.thumbnail_url} />
													</Link>
												))}
											</div>
										)
										: <>Dieser Nutzer hat bisher noch keine Favoriten</>
								}
							</div>
						)
						: null
					}
				</div>

				<div className="card">
					<div className="header">
						<div className="title">Kommentare</div>
						<div className="options">
							{this.state.loading_comments
								? <FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
								: null
							}
							{this.state.error_comments
								? <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
								: null
							}
						</div>
					</div>
					{!this.state.loading_comments
						? (
							<div className="content">
								{this.state.error_comments
									? <div className="error">{this.state.error_comments}</div>
									: this.state.comments.length > 0
										? (
											this.state.comments.map(comment => (
												<div key={comment.ID} className="comment with-thumbnail">
													<Link to={"/i/" + comment.ImageID + "#comment-" + comment.ID}><img src={"/images/" + comment.ThumbnailURL} /></Link>
													<div className="details">
														<div>{richtext.formatText(comment.Comment)}</div>
														<div className="bottom">
															<div>Karma: {comment.Karma}</div>
															<div>{moment(comment.CreatedNanoTS / 1000000).format("[am] D.M.YYYY [um] H:mm")}</div>
														</div>
													</div>
												</div>
											))
										)
										: <>Dieser Nutzer hat bisher noch nichts kommentiert</>
								}
							</div>
						)
						: null
					}
				</div>
			</>
		);
	}
}

export default withAuth(User);